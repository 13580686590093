var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var UE,ZE,Vea,Wea,Xea,Yea,Zea,$ea;
$CLJS.SE=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.oA([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.TE=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.rE(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)};UE=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M};
$CLJS.WE=function(a){var b=function(){var c=UE(a);return c?(c=$CLJS.xd($CLJS.hd(a)))?(c=$CLJS.Fi.h($CLJS.hd(a)),$CLJS.n(c)?c:$CLJS.zB.h($CLJS.hd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.VE.h(a)};
ZE=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,2,[$CLJS.zt,"valid MBQL clause",$CLJS.Bt,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.Ej);return["invalid MBQL clause: ",$CLJS.Mh.l($CLJS.H([c]))].join("")}],null),$CLJS.Te(UE)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.zt,b],null),function(c){c=$CLJS.WE(c);
return YE(c,a)}],null)],null)};$CLJS.$E=new $CLJS.M(null,"right-join","right-join",-56349359);Vea=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Wea=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Xea=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.aF=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.bF=new $CLJS.M(null,"expr","expr",745722291);$CLJS.cF=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.dF=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.eF=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.fF=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.gF=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.hF=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.iF=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);Yea=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.jF=new $CLJS.M(null,"left-join","left-join",-672831855);
$CLJS.kF=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.lF=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.mF=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);Zea=new $CLJS.r("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);
$ea=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.nF=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.oF=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.XE=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.pF=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.qF=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.rF=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.sF=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.VE=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.nC],null),$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.lC(f);return $CLJS.E.g(k,$CLJS.yB)?$CLJS.ab(f):k},e,a,b,c,d)}();
$CLJS.X(Wea,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.hF],null),$CLJS.KE],null));$CLJS.VE.m(null,$CLJS.ci,function(a){throw $CLJS.hi($CLJS.rE("{0}: Don''t know how to determine the type of {1}",$CLJS.H([Zea,$CLJS.Mh.l($CLJS.H([a]))])),new $CLJS.h(null,1,[$CLJS.bF,a],null));});$CLJS.VE.m(null,$CLJS.lF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.WE(a)});
var YE=function YE(a,b){return $CLJS.vd(a)?$CLJS.Re(function(d){return YE.g?YE.g(d,b):YE.call(null,d,b)},a):$CLJS.vd(b)?$CLJS.Re(function(d){return YE.g?YE.g(a,d):YE.call(null,a,d)},b):$CLJS.E.g(a,$CLJS.hF)?!0:$CLJS.zC(a,b)};$CLJS.X($CLJS.fF,ZE($CLJS.Nj,"expression returning a boolean"));$CLJS.X($CLJS.pF,ZE($CLJS.fk,"expression returning a string"));$CLJS.X($CLJS.sF,ZE($CLJS.xj,"expression returning an integer"));$CLJS.X(Xea,ZE($CLJS.WD,"expression returning a non-integer real number"));
$CLJS.X($CLJS.rF,ZE($CLJS.Rj,"expression returning a number"));$CLJS.X(Yea,ZE($CLJS.$C,"expression returning a date"));$CLJS.X($ea,ZE($CLJS.ZD,"expression returning a time"));$CLJS.X(Vea,ZE($CLJS.zD,"expression returning a date time"));$CLJS.X($CLJS.dF,ZE($CLJS.Yj,"expression returning a date, time, or date time"));$CLJS.tF=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.Rj,null,$CLJS.fk,null,$CLJS.Yj,null,$CLJS.Nj,null],null),null);$CLJS.X($CLJS.mF,ZE($CLJS.tF,"an expression that can be compared with :\x3e or :\x3c"));
var afa=new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.JC,null,$CLJS.Rj,null,$CLJS.fk,null,$CLJS.pD,null,$CLJS.Yj,null,$CLJS.Bj,null,$CLJS.Nj,null,$CLJS.UD,null],null),null);$CLJS.X($CLJS.oF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null),ZE($CLJS.pD,"expression returning a BSONID")],null));$CLJS.X($CLJS.kF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,ZE(afa,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.X($CLJS.gF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,ZE($CLJS.Bj,"any type of expression")],null));
$CLJS.X($CLJS.cF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ao,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hj,$CLJS.li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AE,$CLJS.bk],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.li],null)],null)],null)],null));