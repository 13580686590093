var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var l7,n7,o7,dra,era,fra,r7,s7,u7,v7,gra,w7,hra,ira;l7=function(a){var b=new $CLJS.h(null,3,[$CLJS.IE,$CLJS.p.h($CLJS.SE()),$CLJS.zB,$CLJS.zB.h(a),$CLJS.Fi,$CLJS.HA($CLJS.Fi,$CLJS.zB)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wB,b,$CLJS.HA($CLJS.AE,$CLJS.T)(a)],null)};$CLJS.m7=function(a){return $CLJS.C5.h(a)};n7=function(a){return $CLJS.R.j(a,$CLJS.Fj,$CLJS.T4)};
o7=function(a,b){return $CLJS.E.g($CLJS.vE.h(a),$CLJS.gm(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ME,$CLJS.uE,$CLJS.LE],null)))};dra=function(a){return $CLJS.y1(a,new $CLJS.h(null,1,[$CLJS.ME,$CLJS.zh],null))};
era=function(a,b){var c=$CLJS.Re(function(e){return $CLJS.CH(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.RA($CLJS.My);if($CLJS.n($CLJS.QA("metabase.lib.field",c))){var d=$CLJS.rE("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([a])),$CLJS.Mh.l($CLJS.H([$CLJS.Tk.g($CLJS.TZ,b)]))]));d instanceof Error?$CLJS.PA("metabase.lib.field",c,$CLJS.Iw(),d):$CLJS.PA("metabase.lib.field",
c,$CLJS.Iw.l($CLJS.H([d])),null)}return null};
fra=function(a,b,c){if($CLJS.n(p7))return null;var d=p7;p7=!0;try{var e=$CLJS.k2(a,b),f=$CLJS.n(e)?$CLJS.OW(a,e):$CLJS.OW(a,b),k=function(){var m=$CLJS.q7.h(f);if($CLJS.n(m))return m;m=$CLJS.IA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xV,$CLJS.LW],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.XV.h(f);if($CLJS.n(u))return u;u=$CLJS.oP.h(f);if($CLJS.n(u))return u;u=$CLJS.jQ.h(f);return $CLJS.n(u)?u:$CLJS.aF.h(f)}())?$CLJS.U0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.RA($CLJS.My);if($CLJS.n($CLJS.QA("metabase.lib.field",
m))){var t=$CLJS.rE("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.PA("metabase.lib.field",m,$CLJS.Iw(),t):$CLJS.PA("metabase.lib.field",m,$CLJS.Iw.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?era(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.L3($CLJS.Jk.l(l,$CLJS.$i,$CLJS.H([$CLJS.hL,$CLJS.YW,$CLJS.SW])),null),$CLJS.T,function(){var m=$CLJS.TZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.XK,$CLJS.GZ):l:null}finally{p7=d}};
r7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.DP);var f=$CLJS.I(c,2,null);c=$CLJS.il.l($CLJS.H([function(){var k=$CLJS.zB.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.zB,k],null):null}(),function(){var k=$CLJS.HA($CLJS.Fi,$CLJS.zB)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Fi,k],null):null}(),function(){var k=$CLJS.ER.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.YW,k],null):null}(),function(){var k=$CLJS.xG.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.SW,k],null):null}(),$CLJS.Fd(f)?function(){var k=$CLJS.p2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.VK,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.VK,$CLJS.T,f],null):function(){var k=fra(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.VK,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.L3(c,d):c};s7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.SW);return $CLJS.n($CLJS.n(b)?$CLJS.Id($CLJS.zH,b):b)?$CLJS.xj:$CLJS.HA($CLJS.Fi,$CLJS.zB)(a)};
$CLJS.t7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.IE),l=$CLJS.J.g(f,$CLJS.zB),m=$CLJS.J.g(f,$CLJS.ER),t=$CLJS.J.g(f,$CLJS.Fi);e=$CLJS.J.g(f,$CLJS.DP);var u=$CLJS.J.g(f,$CLJS.ON),v=$CLJS.J.g(f,$CLJS.xG),x=$CLJS.il.l;k=new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.VK,$CLJS.m1,k],null);f=$CLJS.wE.h(f);a=$CLJS.n(f)?f:$CLJS.j0.j(a,b,d);c=x.call($CLJS.il,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.wE,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Fi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.zB,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.SW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.YW,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.gL,u):m;return $CLJS.n(e)?$CLJS.L3(u,e):u};u7=function(a,b,c){return $CLJS.n($CLJS.Re(function(d){return $CLJS.E.g($CLJS.si.h(d),c)},a))?$CLJS.Tk.g(function(d){var e=$CLJS.Id(d,b)?$CLJS.Jk.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.si.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
v7=function(a){var b=$CLJS.n($CLJS.U1.h(a))?null:function(){var d=$CLJS.XK.h(a),e=new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.XZ,null,$CLJS.c_,null,$CLJS.GZ,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.il.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.IE,$CLJS.p.h($CLJS.SE()),$CLJS.zB,$CLJS.zB.h(a),$CLJS.Fi,s7(a)],null),function(){var d=$CLJS.N1(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.DP,d],null):null}(),function(){var d=$CLJS.SW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.xG,d],
null):null}(),function(){var d=$CLJS.WW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.WW,d],null):null}(),function(){var d=$CLJS.YW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.ER,d],null):null}(),function(){var d=$CLJS.gL.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.ON,d],null):null}()]));b=($CLJS.n(b)?$CLJS.HA($CLJS.TZ,$CLJS.T):$CLJS.HA($CLJS.$i,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,c,b],null)};
gra=function(a,b){return $CLJS.lf(function(c){return $CLJS.E.g($CLJS.XK.h(c),$CLJS.NZ)},$CLJS.U0.v(a,b,$CLJS.OW(a,b),new $CLJS.h(null,3,[$CLJS.C0,!1,$CLJS.I0,!0,$CLJS.y0,!1],null)))};w7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.q7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);hra=new $CLJS.M(null,"earliest","earliest",-1928154382);ira=new $CLJS.M(null,"latest","latest",24323665);var lra;$CLJS.z1.m(null,$CLJS.rG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zh.h(b),$CLJS.y1(c,new $CLJS.h(null,2,[$CLJS.xG,$CLJS.zh,$CLJS.ER,dra],null)),a],null)});var p7=!1,x7=function x7(a,b){var d=$CLJS.F1(a,w7.h(b));a=$CLJS.n(w7.h(d))?x7.g?x7.g(a,d):x7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Uk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.N0.m(null,$CLJS.VK,function(a,b,c){return s7(c)});$CLJS.N0.m(null,$CLJS.rG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.xG);$CLJS.I(c,2,null);c=r7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.SW,d):c;return $CLJS.O0.j(a,b,c)});$CLJS.P0.m(null,$CLJS.VK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.P0.m(null,$CLJS.rG,function(a,b,c){var d=r7(a,b,c);b=$CLJS.t7(a,b,d,c);return $CLJS.n(w7.h(b))?x7(a,b):b});
$CLJS.J0.m(null,$CLJS.VK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.wE),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.si),l=$CLJS.J.g(c,$CLJS.YW),m=$CLJS.J.g(c,$CLJS.f_),t=$CLJS.J.g(c,$CLJS.gL),u=$CLJS.J.g(c,$CLJS.hL);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.L1.g($CLJS.K1,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.k0)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.F1(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.LA($CLJS.wE.h($CLJS.m0.j(a,b,t)),$CLJS.$W,"")):(u=$CLJS.M1(a,u),a=$CLJS.j0.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.N1(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.hB($CLJS.LA($CLJS.gh(k),"-"," ")),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.z5(l,c),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):m});
$CLJS.J0.m(null,$CLJS.rG,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.ER);var k=$CLJS.J.g(f,$CLJS.DP),l=$CLJS.J.g(f,$CLJS.xG);f=$CLJS.J.g(f,$CLJS.ON);$CLJS.I(c,2,null);c=r7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.f_,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.si,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.YW,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.gL,f):e;return $CLJS.n(e)?$CLJS.j0.v(a,b,e,d):$CLJS.TE("[Unknown Field]")});
$CLJS.K0.m(null,$CLJS.VK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.K0.m(null,$CLJS.rG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=r7(a,b,c);return $CLJS.n(c)?$CLJS.L0.j(a,b,c):"unknown_field"});
$CLJS.Q0.m(null,$CLJS.VK,function(a,b,c){return $CLJS.il.l($CLJS.H([function(){var d=$CLJS.vI($CLJS.Q0,$CLJS.ci);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.XK.h(c),$CLJS.XZ)?function(){var d=$CLJS.e_.h(c);return $CLJS.n(d)?(d=$CLJS.f0(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.XW,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.wE,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.d1.m(null,$CLJS.rG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.xG.h(b)});
$CLJS.d1.m(null,$CLJS.VK,function(a){return $CLJS.SW.h(a)});
$CLJS.c1.m(null,$CLJS.rG,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Id($CLJS.zH,b),e=$CLJS.xC($CLJS.WW,$CLJS.Fi,$CLJS.zB)(c);c=$CLJS.R.l(c,$CLJS.xG,b,$CLJS.H([$CLJS.Fi,d?$CLJS.xj:e,$CLJS.WW,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,c,a],null)}b=$CLJS.WW.h(c);c=$CLJS.n(b)?$CLJS.Jk.g($CLJS.R.j(c,$CLJS.Fi,b),$CLJS.WW):c;c=$CLJS.Jk.g(c,$CLJS.xG);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,c,a],null)});
$CLJS.c1.m(null,$CLJS.VK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.SW,b,$CLJS.H([$CLJS.WW,$CLJS.xC($CLJS.WW,$CLJS.Fi,$CLJS.zB)(a)])):$CLJS.Jk.l(a,$CLJS.SW,$CLJS.H([$CLJS.WW]))});$CLJS.f1.m(null,$CLJS.rG,function(a,b,c){return $CLJS.g1.j(a,b,r7(a,b,c))});
$CLJS.f1.m(null,$CLJS.VK,function(a,b,c){if($CLJS.Pk.g($CLJS.XK.h(c),$CLJS.NZ)){a=$CLJS.HA($CLJS.Fi,$CLJS.zB)(c);b=null==c?null:$CLJS.bO.h(c);if(null==b)var d=null;else try{var e=$CLJS.zD.h($CLJS.nj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,hra),l=$CLJS.J.g(f,ira),m=$CLJS.wna.l($CLJS.H([$CLJS.a1.h(k),$CLJS.a1.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Zr.g?$CLJS.Zr.g(1,m):$CLJS.Zr.call(null,1,m))?$CLJS.Vi:$CLJS.n($CLJS.Zr.g?$CLJS.Zr.g(31,m):$CLJS.Zr.call(null,31,m))?$CLJS.gu:$CLJS.n($CLJS.Zr.g?
$CLJS.Zr.g(365,m):$CLJS.Zr.call(null,365,m))?$CLJS.pj:$CLJS.yj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.zC(a,$CLJS.zD)?$CLJS.Ena:$CLJS.zC(a,$CLJS.$C)?$CLJS.Dna:$CLJS.zC(a,$CLJS.ZD)?$CLJS.Cna:$CLJS.Cf;d=$CLJS.n(d)?u7(e,$CLJS.ci,d):e;return $CLJS.n($CLJS.SW.h(c))?u7(d,$CLJS.r0,$CLJS.SW.h(c)):d}return $CLJS.Cf});
$CLJS.C5.m(null,$CLJS.rG,function(a){var b=null==a?null:$CLJS.PE(a);b=null==b?null:$CLJS.ER.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Fj,$CLJS.B5,$CLJS.H([$CLJS.A5,function(c,d){return r7(c,d,a)}]))});$CLJS.C5.m(null,$CLJS.VK,function(a){var b=null==a?null:$CLJS.YW.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Fj,$CLJS.B5,$CLJS.H([$CLJS.A5,$CLJS.Ue(a)]))});$CLJS.w5.m(null,$CLJS.rG,function(a,b){return $CLJS.QE(a,$CLJS.OE,$CLJS.H([$CLJS.ER,b]))});
$CLJS.w5.m(null,$CLJS.VK,function(a,b){return $CLJS.OE(a,$CLJS.YW,b)});$CLJS.D5.m(null,$CLJS.rG,function(a,b,c){return $CLJS.E5.j(a,b,r7(a,b,c))});
$CLJS.D5.m(null,$CLJS.VK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Fi);var d=$CLJS.J.g(b,$CLJS.bO),e=$CLJS.J.g(b,$CLJS.Xj);if($CLJS.Pk.g($CLJS.XK.h(b),$CLJS.NZ)){var f=function(){var m=null==a?null:$CLJS.D1($CLJS.c0(a));m=null==m?null:$CLJS.nA.h(m);return null==m?null:$CLJS.Id(m,$CLJS.ER)}(),k=$CLJS.IA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,$CLJS.Rj],null)),l=$CLJS.m7(b);return function u(t){return new $CLJS.ne(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.lc(v),
z=$CLJS.D(x),C=$CLJS.qe(z);return function(){for(var K=0;;)if(K<z){var S=$CLJS.kd(x,K),V=C,Z=S;S=o7(S,l)?$CLJS.R.j(Z,$CLJS.r0,!0):Z;V.add(S);K+=1}else return!0}()?$CLJS.te($CLJS.ve(C),u($CLJS.mc(v))):$CLJS.te($CLJS.ve(C),null)}var G=$CLJS.A(v);return $CLJS.ee(function(){var K=G;return o7(G,l)?$CLJS.R.j(K,$CLJS.r0,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Va(function(){if($CLJS.n(f)){var t=$CLJS.ao.h(k);return $CLJS.n(t)?$CLJS.lk.h(k):t}return f}())?null:$CLJS.zC(e,$CLJS.zj)?$CLJS.Tk.g(n7,
new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.y5(),new $CLJS.h(null,2,[$CLJS.wE,$CLJS.TE("Bin every 0.1 degrees"),$CLJS.vE,new $CLJS.h(null,2,[$CLJS.ME,$CLJS.LE,$CLJS.LE,.1],null)],null),new $CLJS.h(null,2,[$CLJS.wE,$CLJS.TE("Bin every 1 degree"),$CLJS.vE,new $CLJS.h(null,2,[$CLJS.ME,$CLJS.LE,$CLJS.LE,1],null)],null),new $CLJS.h(null,2,[$CLJS.wE,$CLJS.TE("Bin every 10 degrees"),$CLJS.vE,new $CLJS.h(null,2,[$CLJS.ME,$CLJS.LE,$CLJS.LE,10],null)],null),new $CLJS.h(null,2,[$CLJS.wE,$CLJS.TE("Bin every 20 degrees"),
$CLJS.vE,new $CLJS.h(null,2,[$CLJS.ME,$CLJS.LE,$CLJS.LE,20],null)],null)],null)):$CLJS.zC(c,$CLJS.Rj)&&!$CLJS.zC(e,$CLJS.ni)?$CLJS.Tk.g(n7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.y5(),new $CLJS.h(null,2,[$CLJS.wE,$CLJS.TE("10 bins"),$CLJS.vE,new $CLJS.h(null,2,[$CLJS.ME,$CLJS.uE,$CLJS.uE,10],null)],null),new $CLJS.h(null,2,[$CLJS.wE,$CLJS.TE("50 bins"),$CLJS.vE,new $CLJS.h(null,2,[$CLJS.ME,$CLJS.uE,$CLJS.uE,50],null)],null),new $CLJS.h(null,2,[$CLJS.wE,$CLJS.TE("100 bins"),$CLJS.vE,new $CLJS.h(null,2,
[$CLJS.ME,$CLJS.uE,$CLJS.uE,100],null)],null)],null)):null)}return $CLJS.Cf});$CLJS.xW.m(null,$CLJS.rG,function(a){return a});
$CLJS.xW.m(null,$CLJS.VK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.XK);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.IE,$CLJS.p.h($CLJS.SE()),$CLJS.Fi,$CLJS.HA($CLJS.Fi,$CLJS.zB)(a)],null),a=$CLJS.m1.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,b,a],null);case "source/expressions":return l7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.AE.h(a))?l7(a):v7(a);default:return v7(a)}});
$CLJS.jra=function(){function a(e,f,k){k=$CLJS.Fe($CLJS.Tk.g($CLJS.zW,k));var l=gra(e,f),m=$CLJS.eg.j($CLJS.bh,$CLJS.Zl(function(t){return $CLJS.v2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Cf);m=$CLJS.Sk.g(m,l);k=$CLJS.n(k)?$CLJS.eg.j(k,$CLJS.hf.h($CLJS.zW),m):null;return $CLJS.ZW.l(e,f,$CLJS.OE,$CLJS.H([$CLJS.aF,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.y7=function(){function a(d,e){return $CLJS.aF.h($CLJS.OW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.kra=function(){function a(d,e){var f=$CLJS.U0.v(d,e,$CLJS.OW(d,e),new $CLJS.h(null,3,[$CLJS.C0,!1,$CLJS.I0,!1,$CLJS.y0,!1],null)),k=$CLJS.y7.g(d,e);return $CLJS.td(k)?$CLJS.Tk.g(function(l){return $CLJS.R.j(l,$CLJS.VZ,!0)},f):$CLJS.J3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
lra=function(){function a(d,e,f){var k=$CLJS.OW(d,e),l=$CLJS.E.g($CLJS.lC(f),$CLJS.gG)?$CLJS.S0:$CLJS.U0;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.v2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.mra=function(){function a(d,e,f){f=$CLJS.CZ.j(d,e,f);return lra.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();