var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var JL,LL,NL,QL,VL;$CLJS.EL=function(a){return $CLJS.Wh($CLJS.q($CLJS.nC),a,$CLJS.vG)};$CLJS.FL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.GL=new $CLJS.M(null,"row","row",-570139521);$CLJS.HL=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.IL=new $CLJS.M(null,"object-id","object-id",-754527291);JL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);
$CLJS.KL=new $CLJS.M(null,"operators","operators",-2064102509);LL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.ML=new $CLJS.M(null,"stage-number","stage-number",-1752729638);NL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.OL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.PL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
QL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.RL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.SL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.TL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.UL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
VL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.WL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.XL=new $CLJS.M("drill-thru","zoom","drill-thru/zoom",-1212878631);$CLJS.X(LL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,$CLJS.GE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.lH],null)],null)],null));$CLJS.X(VL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,LL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,$CLJS.KE],null)],null)],null));$CLJS.X(NL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.rG],null),VL,$CLJS.BE],null));
$CLJS.X(QL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.rG],null),LL,$CLJS.nL],null));
$CLJS.RG.g($CLJS.rG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.rG],null),LL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.nL,$CLJS.BE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.h(null,2,[$CLJS.Pi,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.lC(a)):null},$CLJS.zt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.qB,QL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,NL],null)],null)],null));$CLJS.zF($CLJS.rG,$CLJS.vG);$CLJS.VE.m(null,$CLJS.rG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.HA($CLJS.Fi,$CLJS.zB)(b);return $CLJS.n(a)?a:$CLJS.hF});$CLJS.GF($CLJS.wB,$CLJS.H([$CLJS.BE]));$CLJS.VE.m(null,$CLJS.wB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.HA($CLJS.Fi,$CLJS.zB)(b);return $CLJS.n(a)?a:$CLJS.hF});
$CLJS.zF($CLJS.wB,$CLJS.vG);$CLJS.X(JL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,$CLJS.GE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.BE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wE,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.BE],null)],null)],null));$CLJS.RG.g($CLJS.gG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.gG],null),JL,$CLJS.bk],null));
$CLJS.VE.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.HA($CLJS.Fi,$CLJS.zB)(b);return $CLJS.n(a)?a:$CLJS.hF});$CLJS.zF($CLJS.gG,$CLJS.vG);$CLJS.GF($CLJS.QF,$CLJS.H([$CLJS.st,$CLJS.Nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YK],null)],null)]));$CLJS.zF($CLJS.QF,$CLJS.vG);
$CLJS.GF($CLJS.mG,$CLJS.H([$CLJS.st,$CLJS.hF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WK],null)],null)]));$CLJS.zF($CLJS.mG,$CLJS.vG);
$CLJS.X($CLJS.vG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.XE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Bt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Kt(", ",$CLJS.cH($CLJS.Jd,$CLJS.ml.g($CLJS.q($CLJS.nC),$CLJS.vG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.EL(a)}],null)],null));