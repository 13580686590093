var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var h9,j9,i9,Jva,p9,Kva,q9,Lva,Mva,Nva,Ova,k9,Pva,v9,Qva,Rva,Sva;h9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Ca(a,b):null};j9=function(a,b){return i9(a,b,$CLJS.N)};
i9=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.j(c,k9,!0);if($CLJS.xd(b))return $CLJS.eg.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=h9(b,"_plainObject");d=null==d?null:$CLJS.Vz(d);return null==d?null:$CLJS.Fe(d)}():null;return $CLJS.n(c)?$CLJS.eg.j($CLJS.N,a,c):$CLJS.eg.j($CLJS.N,$CLJS.Rk.j($CLJS.hf.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,h9(b,d)],null)}),$CLJS.Sk.h(function(d){$CLJS.I(d,0,null);return"function"===typeof $CLJS.I(d,1,null)}),a),$CLJS.Ba(b))};
Jva=function(a){var b=l9.h(a),c=m9.h(a),d=n9.h(a);return $CLJS.Rk.j($CLJS.hf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=$CLJS.Q;f=$CLJS.zh.h($CLJS.jB.h?$CLJS.jB.h(f):$CLJS.jB.call(null,f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.P(null,2,5,k,[f,e],null)}),$CLJS.td(b)?$CLJS.Td:$CLJS.Sk.h(function(e){var f=$CLJS.I(e,0,null);$CLJS.I(e,1,null);return $CLJS.Id(b,f)}),$CLJS.Va(c)?$CLJS.Td:$CLJS.hf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,
1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};
p9=function(a,b){var c=Jva(a),d=o9.h(a);return function(e){try{var f=$CLJS.R.j(i9(c,e,b),$CLJS.Fj,d),k=$CLJS.RA($CLJS.Jy);$CLJS.n($CLJS.QA("metabase.lib.js.metadata",k))&&$CLJS.PA("metabase.lib.js.metadata",k,$CLJS.DW("Parsed metadata %s %s\n%s",$CLJS.H([a,$CLJS.$i.h(f),$CLJS.EW(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.RA($CLJS.Ky),$CLJS.n($CLJS.QA("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.PA("metabase.lib.js.metadata",k,$CLJS.DW("Error parsing %s %s: %s",
$CLJS.H([a,$CLJS.Mh.l($CLJS.H([e])),$CLJS.CV(f)])),f):$CLJS.PA("metabase.lib.js.metadata",k,$CLJS.DW(f,$CLJS.H(["Error parsing %s %s: %s",a,$CLJS.Mh.l($CLJS.H([e])),$CLJS.CV(f)])),null)),null;throw l;}}};Kva=function(a){return j9($CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.zh.h($CLJS.jB.h?$CLJS.jB.h(c):$CLJS.jB.call(null,c));c=$CLJS.E.g(c,$CLJS.LV)?$CLJS.ME:c;b=$CLJS.E.g(c,$CLJS.ME)?$CLJS.zh.h(b):b;return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),a)};
q9=function(a){return $CLJS.Tk.g(p9($CLJS.rG,$CLJS.N),a)};Lva=new $CLJS.M(null,"plain-object","plain-object",-943264281);$CLJS.r9=new $CLJS.M(null,"cards","cards",169174038);Mva=new $CLJS.M(null,"binning-info","binning-info",-2117036653);Nva=new $CLJS.M(null,"db","db",993250759);$CLJS.s9=new $CLJS.M(null,"metrics","metrics",394093469);Ova=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.t9=new $CLJS.M(null,"segments","segments",1937535949);
k9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);Pva=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.u9=new $CLJS.M(null,"tables","tables",1334623052);v9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);Qva=new $CLJS.M(null,"schema-name","schema-name",1666725119);Rva=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);Sva=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);
$CLJS.w9=new $CLJS.M(null,"databases","databases",2040134125);var l9,Tva=$CLJS.Xe($CLJS.N),Uva=$CLJS.Xe($CLJS.N),Vva=$CLJS.Xe($CLJS.N),Wva=$CLJS.Xe($CLJS.N),Xva=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null)),y9,mwa,nwa,owa,pwa,qwa;l9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.zh,Xva,Tva,Uva,Vva,Wva);l9.m(null,$CLJS.ci,function(){return null});var m9,Yva=$CLJS.Xe($CLJS.N),Zva=$CLJS.Xe($CLJS.N),$va=$CLJS.Xe($CLJS.N),awa=$CLJS.Xe($CLJS.N),bwa=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
m9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.zh,bwa,Yva,Zva,$va,awa);m9.m(null,$CLJS.ci,function(){return null});var o9,cwa=$CLJS.Xe($CLJS.N),dwa=$CLJS.Xe($CLJS.N),ewa=$CLJS.Xe($CLJS.N),fwa=$CLJS.Xe($CLJS.N),gwa=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));o9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","lib-type"),$CLJS.zh,gwa,cwa,dwa,ewa,fwa);
var n9,hwa=$CLJS.Xe($CLJS.N),iwa=$CLJS.Xe($CLJS.N),jwa=$CLJS.Xe($CLJS.N),kwa=$CLJS.Xe($CLJS.N),lwa=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));n9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Td,lwa,hwa,iwa,jwa,kwa);n9.m(null,$CLJS.ci,function(){return null});
$CLJS.x9=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.zh.h(f)},e,a,b,c,d)}();mwa=$CLJS.Xe($CLJS.N);nwa=$CLJS.Xe($CLJS.N);owa=$CLJS.Xe($CLJS.N);pwa=$CLJS.Xe($CLJS.N);qwa=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
y9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.zh,qwa,mwa,nwa,owa,pwa);$CLJS.x9.m(null,$CLJS.ci,function(a,b){var c=p9(a,$CLJS.N);return j9($CLJS.hf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CW(e),new $CLJS.Jh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),h9(b,y9.h(a)))});o9.m(null,$CLJS.dQ,function(){return $CLJS.lL});
l9.m(null,$CLJS.dQ,function(){return new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.aF,null,$CLJS.u9,null],null),null)});m9.m(null,$CLJS.dQ,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "dbms-version":return $CLJS.Uz(b,$CLJS.H([$CLJS.zi,!0]));case "features":return $CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.zh),b);case "native-permissions":return $CLJS.zh.h(b);default:return b}}});y9.m(null,$CLJS.dQ,function(){return"databases"});o9.m(null,$CLJS.XW,function(){return $CLJS.bL});
l9.m(null,$CLJS.XW,function(){return new $CLJS.ah(null,new $CLJS.h(null,5,[v9,null,$CLJS.aF,null,$CLJS.t9,null,$CLJS.dQ,null,$CLJS.s9,null],null),null)});m9.m(null,$CLJS.XW,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "entity-type":return $CLJS.zh.h(b);case "field-order":return $CLJS.zh.h(b);case "initial-sync-status":return $CLJS.zh.h(b);case "visibility-type":return $CLJS.zh.h(b);default:return b}}});
$CLJS.x9.m(null,$CLJS.XW,function(a,b){var c=p9(a,$CLJS.N);return j9($CLJS.Rk.g($CLJS.Sk.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.hf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CW(e),new $CLJS.Jh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),h9(b,"tables"))});o9.m(null,$CLJS.rG,function(){return $CLJS.VK});
l9.m(null,$CLJS.rG,function(){return new $CLJS.ah(null,new $CLJS.h(null,7,[v9,null,$CLJS.XW,null,$CLJS.PL,null,Sva,null,$CLJS.dQ,null,Rva,null,$CLJS.s9,null],null),null)});n9.m(null,$CLJS.rG,function(){return new $CLJS.h(null,4,[$CLJS.hA,$CLJS.XK,$CLJS.si,$CLJS.SW,$CLJS.kS,$CLJS.AE,Mva,$CLJS.YW],null)});
m9.m(null,$CLJS.rG,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "base-type":return $CLJS.zh.h(b);case "coercion-strategy":return $CLJS.zh.h(b);case "effective-type":return $CLJS.zh.h(b);case "fingerprint":return $CLJS.xd(b)?$CLJS.EV(b):$CLJS.Uz(b,$CLJS.H([$CLJS.zi,!0]));case "has-field-values":return $CLJS.zh.h(b);case "lib/source":switch(b){case "aggregation":return $CLJS.pL;case "breakout":return $CLJS.jL;default:return $CLJS.zh.g("source",b)}case "metabase.lib.field/temporal-unit":return $CLJS.zh.h(b);
case "semantic-type":return $CLJS.zh.h(b);case "visibility-type":return $CLJS.zh.h(b);case "id":return $CLJS.zd(b)?$CLJS.hd(b):b;case "metabase.lib.field/binning":return Kva(b);default:return b}}});
$CLJS.x9.m(null,$CLJS.rG,function(a,b){var c=p9(a,$CLJS.N),d=h9(b,"fields");return j9($CLJS.Zl(function(e){var f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null);e=function(){var l=$CLJS.CW(f);if($CLJS.n(l))return l;l=$CLJS.nh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);var m=null==h9(d,l);return m?$CLJS.CW(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.Jh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});o9.m(null,$CLJS.FN,function(){return $CLJS.oL});
l9.m(null,$CLJS.FN,function(){return new $CLJS.ah(null,new $CLJS.h(null,11,[v9,null,$CLJS.sl,null,Ova,null,$CLJS.XW,null,Nva,null,Lva,null,$CLJS.t9,null,$CLJS.dQ,null,Pva,null,$CLJS.s9,null,Qva,null],null),null)});
m9.m(null,$CLJS.FN,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "result-metadata":return $CLJS.n($CLJS.HA($CLJS.wd,$CLJS.Ua)(b))?q9(b):$CLJS.Uz(b,$CLJS.H([$CLJS.zi,!0]));case "fields":return q9(b);case "visibility-type":return $CLJS.zh.h(b);case "dataset-query":return $CLJS.Uz(b,$CLJS.H([$CLJS.zi,!0]));case "dataset":return b;default:return b}}});
$CLJS.x9.m(null,$CLJS.FN,function(a,b){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.Jh(function(){var d=p9($CLJS.FN,new $CLJS.h(null,1,[k9,!1],null)),e=p9($CLJS.FN,$CLJS.N),f=$CLJS.il.l;d=$CLJS.R.j(d(h9(h9(b,"tables"),["card__",$CLJS.p.h(c)].join(""))),$CLJS.$i,c);var k=h9(h9(b,"questions"),$CLJS.p.h(c));var l=h9(k,"_card");k=$CLJS.n(l)?l:k;e=e.h?e.h(k):e.call(null,k);return f.call($CLJS.il,$CLJS.H([d,e]))})],null)}),$CLJS.eg.j($CLJS.eg.j($CLJS.bh,
$CLJS.Zl($CLJS.PW),$CLJS.Ba(h9(b,"tables"))),$CLJS.hf.h($CLJS.CW),$CLJS.Ba(h9(b,"questions"))))});o9.m(null,$CLJS.mG,function(){return $CLJS.aL});l9.m(null,$CLJS.mG,function(){return new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.XW,null,$CLJS.dQ,null],null),null)});m9.m(null,$CLJS.mG,function(){return function(a,b){return b}});y9.m(null,$CLJS.mG,function(){return"metrics"});o9.m(null,$CLJS.QF,function(){return $CLJS.ZK});
l9.m(null,$CLJS.QF,function(){return new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.XW,null,$CLJS.dQ,null],null),null)});m9.m(null,$CLJS.QF,function(){return function(a,b){return b}});y9.m(null,$CLJS.QF,function(){return"segments"});$CLJS.z9=p9($CLJS.rG,$CLJS.N);