var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var sfa,tfa,ufa,vfa,wfa,xfa,yfa,zfa,Afa;sfa=function(a,b){a.sort(b||$CLJS.Da)};tfa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Da;sfa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.bH=function(a){return $CLJS.E.g(a,$CLJS.Jd)?$CLJS.Jd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.cH=function(a,b){if($CLJS.y(b)){var c=$CLJS.we.h?$CLJS.we.h(b):$CLJS.we.call(null,b);tfa(c,$CLJS.bH(a));return $CLJS.od($CLJS.y(c),$CLJS.pd(b))}return $CLJS.Kc};$CLJS.dH=new $CLJS.M(null,"second","second",-444702010);$CLJS.eH=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.fH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.gH=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.hH=new $CLJS.M(null,"year-of-era","year-of-era",682445876);ufa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
vfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.iH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.jH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);
wfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);xfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);yfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);
zfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.kH=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.lH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.mH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
Afa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var nH=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Gi,$CLJS.Ij,$CLJS.Gj,$CLJS.qi,$CLJS.ok,$CLJS.Ai,$CLJS.ji,$CLJS.hH],null),oH=$CLJS.fh(nH),Bfa,Cfa,Dfa;$CLJS.X(yfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid date extraction unit"],null)],null),oH));var pH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gu,$CLJS.pj,$CLJS.yj,$CLJS.pi,$CLJS.ji],null),qH=$CLJS.fh(pH);
$CLJS.X(xfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid date truncation unit"],null)],null),qH));$CLJS.rH=$CLJS.eg.j($CLJS.Cf,$CLJS.kl.o(),$CLJS.gf.g(pH,nH));Bfa=$CLJS.fh($CLJS.rH);$CLJS.X($CLJS.jH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid date bucketing unit"],null)],null),Bfa));var sH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eH,$CLJS.jj,$CLJS.wi],null),tH=$CLJS.fh(sH);
$CLJS.X(wfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid time extraction unit"],null)],null),tH));var uH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gH,$CLJS.dH,$CLJS.Vi,$CLJS.sk],null),vH=$CLJS.fh(uH);$CLJS.X(Afa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid time truncation unit"],null)],null),vH));$CLJS.wH=$CLJS.eg.j($CLJS.Cf,$CLJS.kl.o(),$CLJS.gf.g(uH,sH));Cfa=$CLJS.fh($CLJS.wH);
$CLJS.X(vfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid time bucketing unit"],null)],null),Cfa));$CLJS.xH=$CLJS.eg.j($CLJS.Cf,$CLJS.kl.o(),$CLJS.gf.l(uH,pH,$CLJS.H([sH,nH])));Dfa=$CLJS.fh($CLJS.xH);$CLJS.X($CLJS.fH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid datetime bucketing unit"],null)],null),$CLJS.xH));var Efa=$CLJS.be.g(Dfa,$CLJS.ci);
$CLJS.X($CLJS.lH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid temporal bucketing unit"],null)],null),Efa));$CLJS.yH=$CLJS.hu.g(qH,vH);$CLJS.X($CLJS.iH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid datetime truncation unit"],null)],null),$CLJS.yH));$CLJS.zH=$CLJS.hu.g(oH,tH);$CLJS.X(ufa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid datetime extraction unit"],null)],null),$CLJS.zH));
var AH=$CLJS.be.g(qH,$CLJS.ji);$CLJS.X($CLJS.mH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid date interval unit"],null)],null),AH));$CLJS.X($CLJS.nE,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid time interval unit"],null)],null),vH));var Ffa=$CLJS.hu.g(AH,vH);$CLJS.X($CLJS.oE,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid datetime interval unit"],null)],null),Ffa));
$CLJS.X(zfa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.kH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.si,$CLJS.lH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Qs],null)],null));