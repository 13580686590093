var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var mV,nV,oV,qV,rV,sV,tV,uV,vV,wV;mV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);nV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);oV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.pV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);qV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);rV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);sV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
tV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);uV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);vV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);wV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(mV,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.zt,"Valid template tag :widget-type"],null),$CLJS.mA],null),$CLJS.Xg($CLJS.hV)));$CLJS.X(qV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xs,$CLJS.QM,$CLJS.FN,$CLJS.LF,$CLJS.au,$CLJS.DB,$CLJS.LP],null));
$CLJS.X(tV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.BE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wE,$CLJS.BE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.BE,$CLJS.Ps],null)],null)],null));
$CLJS.X(uV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Fl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gO,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Qs],null)],null)],null));
$CLJS.X(rV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.LF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$N,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.Us,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.kk],null)],null)],null)],null));
$CLJS.X(sV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.QM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CM,$CLJS.BE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cQ,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.mL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dQ,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.fL],null)],null)],null));
$CLJS.X(wV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,$CLJS.FN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mR,$CLJS.eL],null)],null)],null));$CLJS.X(vV,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.xs],null),$CLJS.WU));
$CLJS.X(nV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vV],null)],null)],null)],null));
$CLJS.X(oV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.h(null,1,[$CLJS.Pi,$CLJS.nj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null)],null)],null)],null));
$CLJS.X($CLJS.pV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.BE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.zt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));