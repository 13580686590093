var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var eX,fX,hX,iX,ela,kX,lX,dX,mX,fla,gla,hla,ila,qX,rX,tX,uX,vX,wX,xX,yX,kla,lla,AX,mla,BX,nla,DX,ola,pla,qla,rla,sla,aX;$CLJS.bX=function(a,b){if("string"===typeof b)return aX(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.cX=function(a,b,c){var d=$CLJS.hm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
eX=function(a){var b=dX;return $CLJS.NA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};fX=function(a){if($CLJS.md(a))return a;throw $CLJS.hi("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Hi,a],null));};
$CLJS.gX=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Tk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};hX=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
iX=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
ela=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.Yk.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.Yk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
kX=function(a,b){for(;;)switch(b=$CLJS.kl.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),jX.h?jX.h(a):jX.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve(iX,a),b))){var c=a;b=ela(a,b);a=c}else{c=$CLJS.hf.g(jX,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};lX=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.HA($CLJS.ge,$CLJS.Ya)($CLJS.A(a)):b:b};
dX=function(a){return $CLJS.n($CLJS.HA($CLJS.ge,$CLJS.Ya)(a))?$CLJS.zh.h($CLJS.LA(hX(a).toLowerCase(),/_/,"-")):a};mX=function(a,b){var c=lX(b);return $CLJS.n(c)?(b=dX($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};fla=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.ci,$CLJS.Td,$CLJS.nj,dX,$CLJS.$N,dX],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=nX.g?nX.g(b,oX):nX.call(null,b,oX);return d.call(c,b,a)})};
gla=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=dX(c);var d=fla(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.nj.h(a),$CLJS.LF)&&$CLJS.Va($CLJS.$N.h(a))?$CLJS.R.j(a,$CLJS.$N,$CLJS.KG):a};hla=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=hX(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(gla(b),$CLJS.T,c)],null)}),a)};
ila=function(a){a=nX.h?nX.h(a):nX.call(null,a);return pX.h?pX.h(a):pX.call(null,a)};qX=function(a){return $CLJS.cX($CLJS.cX($CLJS.eb(function(b,c){return $CLJS.cX(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.mD,$CLJS.Cea,$CLJS.NO,jla,$CLJS.hA,$CLJS.si],null)),$CLJS.mC,ila),$CLJS.bO,$CLJS.EV)};rX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,a,null],null):a};tX=function(a){return sX.h(rX(a))};
uX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return jX($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(sX,b)))};vX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,tX(b)],null),$CLJS.hf.g(sX,c))};wX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,tX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
xX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,tX(a)],null)};yX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,sX.h(a)],null)};kla=function(a){return $CLJS.Df($CLJS.em(pX,zX(a)))};lla=function(a){for(;;)if($CLJS.n(lX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Tk.g(rX,a))};
AX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.LF);return $CLJS.n(b)?$CLJS.Uk.j(a,$CLJS.LF,sX):a};
mla=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,AX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,AX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};BX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.QQ);return $CLJS.n(b)?$CLJS.Uk.j(a,$CLJS.QQ,mla):a};nla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.tQ);b=$CLJS.Va(a)?CX.h?CX.h(b):CX.call(null,b):b;return $CLJS.n(a)?BX(b):b};DX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
ola=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.OF);var c=$CLJS.J.g(b,$CLJS.EN),d=$CLJS.J.g(b,$CLJS.aP),e=$CLJS.J.g(b,$CLJS.tQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.aP);var l=$CLJS.fM($CLJS.Jk.g(f,$CLJS.aP),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OF,$CLJS.aP],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Uk.j(l,$CLJS.OF,CX):l,t=$CLJS.n(c)?$CLJS.Uk.j(m,$CLJS.EN,$CLJS.Ve($CLJS.Tk,pX)):m,u=$CLJS.n(e)?$CLJS.Uk.j(t,$CLJS.tQ,BX):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.tQ);return $CLJS.n(x)?$CLJS.R.j(pX($CLJS.Jk.g(v,
$CLJS.tQ)),$CLJS.tQ,x):pX(v)}catch(z){if(z instanceof Error)throw m=z,$CLJS.gi($CLJS.rE("Error canonicalizing query: {0}",$CLJS.H([$CLJS.CV(m)])),new $CLJS.h(null,1,[$CLJS.OF,a],null),m);throw z;}};
pla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.OF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.fG);c=$CLJS.J.g(c,$CLJS.aF);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.yC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.rG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,m,$CLJS.Jk.g(t,$CLJS.xG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.JV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.EX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OF,$CLJS.aF],null),$CLJS.Rk.g($CLJS.Df,$CLJS.Ve($CLJS.Sk,b)))):a};
qla=function(a){try{return pla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.gi($CLJS.TE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.OF,a],null),b);}throw c;}};
rla=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=FX.g?FX.g(C,K):FX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return FX.g?FX.g(z,C):FX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};sla=function(a,b){a=$CLJS.Tk.g(function(c){var d=$CLJS.be.g(b,GX);return FX.g?FX.g(c,d):FX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
aX=function aX(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),aX.g?aX.g(a,k):aX.call(null,a,k)):null},null,null))};
$CLJS.EX=function EX(a){switch(arguments.length){case 3:return EX.j(arguments[0],arguments[1],arguments[2]);case 4:return EX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return EX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return EX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return EX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.EX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.EX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.EX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.EX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.EX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.EX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.EX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.EX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.EX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.EX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.EX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.EX.A=6;
var jX=function jX(a){for(;;){if($CLJS.xd(a))return $CLJS.Jt(a,jX);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Ol(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return kX($CLJS.As,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Cs,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return kX($CLJS.Hs,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return kX($CLJS.Hs,t);case "or":return kX($CLJS.As,t);default:return $CLJS.Tk.g(jX,a)}}else return a}},tla=new $CLJS.M(null,"value_field","value_field",-980977878),HX=new $CLJS.M(null,"ascending","ascending",-988350486),
IX=new $CLJS.M(null,"named","named",-422393479),JX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),KX=new $CLJS.M(null,"descending","descending",-24766135),LX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),ula=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),MX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),NX=new $CLJS.M(null,"viz-settings","viz-settings",256055379),vla=new $CLJS.M(null,"rows","rows",850049680),wla=
new $CLJS.M(null,"special-fn","special-fn",1290649344),GX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),oX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),xla=new $CLJS.M(null,"label_field","label_field",-1573182765),yla=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),zla=new $CLJS.M(null,"joined-field","joined-field",-2048778268),jla=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var OX,Ala=$CLJS.Xe($CLJS.N),Bla=$CLJS.Xe($CLJS.N),Cla=$CLJS.Xe($CLJS.N),Dla=$CLJS.Xe($CLJS.N),Ela=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));OX=new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Rk.g(dX,$CLJS.A),Ela,Ala,Bla,Cla,Dla);OX.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,b],null);return null!=a?$CLJS.be.g(b,a):b});
OX.m(null,$CLJS.wB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,a instanceof $CLJS.M?hX(a):a],null)});OX.m(null,$CLJS.LV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(OX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LV,nX.g?nX.g(b,oX):nX.call(null,b,oX),dX(c)],null)});
OX.m(null,$CLJS.rG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=nX.g?nX.g(a,oX):nX.call(null,a,oX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,b,function(){var d=$CLJS.n($CLJS.zB.h(c))?$CLJS.Uk.j(c,$CLJS.zB,$CLJS.zh):c;d=$CLJS.n($CLJS.xG.h(c))?$CLJS.Uk.j(d,$CLJS.xG,$CLJS.zh):d;return $CLJS.n($CLJS.ER.h(c))?$CLJS.Uk.j(d,$CLJS.ER,function(e){return $CLJS.n($CLJS.ME.h(e))?$CLJS.Uk.j(e,$CLJS.ME,$CLJS.zh):e}):d}()],null)});
OX.m(null,MX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[MX,b instanceof $CLJS.M?hX(b):b,$CLJS.zh.h(a)],null)});OX.m(null,LX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[LX,nX.g?nX.g(b,oX):nX.call(null,b,oX),$CLJS.qt,dX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[LX,nX.g?nX.g(b,oX):nX.call(null,b,oX),dX(c)],null)});
OX.m(null,$CLJS.DG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(OX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.DG,b,c,d],null)),nX.g?nX.g(a,oX):nX.call(null,a,oX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.DG,nX.g?nX.g(b,oX):nX.call(null,b,oX),$CLJS.Fd(c)?c:dX(c),dX(d)],null)});
OX.m(null,$CLJS.iJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iJ,b,dX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iJ,$CLJS.aw],null)});OX.m(null,$CLJS.gJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gJ,b,dX(a)],null)});
OX.m(null,$CLJS.qJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qJ,nX.g?nX.g(b,oX):nX.call(null,b,oX),c,dX(a)],null)});OX.m(null,$CLJS.wJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.wJ,nX.g?nX.g(b,oX):nX.call(null,b,oX),c,dX(a)],null)});
OX.m(null,$CLJS.oJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oJ,nX.g?nX.g(b,oX):nX.call(null,b,oX),dX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,nX.g?nX.g(b,oX):nX.call(null,b,oX)],null)});
OX.m(null,$CLJS.uJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.uJ,nX.g?nX.g(b,oX):nX.call(null,b,oX),dX(c),dX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uJ,nX.g?nX.g(b,oX):nX.call(null,b,oX),dX(c)],null)});
OX.m(null,$CLJS.fJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fJ,nX.g?nX.g(b,oX):nX.call(null,b,oX),nX.g?nX.g(c,oX):nX.call(null,c,oX),dX(a)],null)});OX.m(null,$CLJS.Ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,b,a],null)});
OX.m(null,$CLJS.ci,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[dX(a)],null),$CLJS.hf.h(function(c){return nX.g?nX.g(c,oX):nX.call(null,c,oX)}),b)});
var PX=function PX(a){if($CLJS.n($CLJS.HA($CLJS.ge,$CLJS.Ya)(a))){var c=dX(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.ao,null,$CLJS.hs,null,$CLJS.GG,null,$CLJS.AG,null,$CLJS.st,null,$CLJS.sG,null,$CLJS.LG,null,$CLJS.MG,null,$CLJS.XF,null,$CLJS.cG,null,$CLJS.gs,null,$CLJS.YF,null,$CLJS.FG,null,$CLJS.yG,null,$CLJS.lk,null,$CLJS.Zx,null,$CLJS.lG,null,$CLJS.EG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(lX(a))?(a=$CLJS.A(a),PX.h?PX.h(a):PX.call(null,
a)):null},Fla=new $CLJS.h(null,8,[$CLJS.nj,dX,$CLJS.tQ,function(a){a=eX(a);return $CLJS.y($CLJS.QQ.h(a))?$CLJS.Uk.j(a,$CLJS.QQ,hla):a},$CLJS.OF,new $CLJS.h(null,6,[$CLJS.gG,function QX(a){if($CLJS.n($CLJS.HA($CLJS.ge,$CLJS.Ya)(a)))return dX(a);if($CLJS.n(mX(IX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[IX,QX.h?QX.h(a):QX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(lX(a))?PX($CLJS.hd(a)):null)?$CLJS.Tk.g(QX,a):nX.g?nX.g(a,
oX):nX.call(null,a,oX)},$CLJS.jQ,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[hX(u),nX.g?nX.g(t,oX):nX.call(null,t,oX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[hX(l),nX.g?nX.g(f,oX):nX.call(null,f,oX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.ZR,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(lX(t))?OX.h(t):$CLJS.ce(OX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n(lX(l))?OX.h(l):$CLJS.ce(OX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.SQ,function(a){a=eX(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.tQ);if($CLJS.n(b))return a=$CLJS.IV(a,new $CLJS.h(null,1,[$CLJS.tQ,$CLJS.OF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.tQ],null),a=nX.g?nX.g(a,b):nX.call(null,a,b),$CLJS.IV(a,new $CLJS.h(null,1,[$CLJS.OF,$CLJS.tQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.OF],null);return nX.g?nX.g(a,b):nX.call(null,a,b)},$CLJS.aP,new $CLJS.h(null,1,[GX,qX],null),$CLJS.NN,new $CLJS.h(null,1,[GX,function(a){a=nX.g?nX.g(a,$CLJS.OF):nX.call(null,a,$CLJS.OF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.ME),d=$CLJS.J.g(b,$CLJS.aF);a=$CLJS.J.g(b,$CLJS.eF);b=$CLJS.n(c)?$CLJS.Uk.j(b,$CLJS.ME,dX):b;d=$CLJS.n($CLJS.HA($CLJS.ge,$CLJS.Ya)(d))?$CLJS.Uk.j(b,$CLJS.aF,dX):b;return $CLJS.n(a)?$CLJS.Uk.j(d,$CLJS.eF,hX):d}],null)],null),$CLJS.Oy,
new $CLJS.h(null,1,[$CLJS.yO,$CLJS.Td],null),$CLJS.EN,new $CLJS.h(null,1,[GX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.nj),d=$CLJS.J.g(b,$CLJS.BJ),e=$CLJS.J.g(b,$CLJS.$i);a=$CLJS.J.g(b,JX);b=$CLJS.n(e)?$CLJS.Uk.j(b,$CLJS.$i,hX):b;c=$CLJS.n(c)?$CLJS.Uk.j(b,$CLJS.nj,dX):b;d=$CLJS.n(d)?$CLJS.Uk.j(c,$CLJS.BJ,function(f){return nX.g?nX.g(f,oX):nX.call(null,f,oX)}):c;d=$CLJS.n(a)?$CLJS.EX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[JX,xla],null),function(f){return nX.g?nX.g(f,oX):nX.call(null,f,oX)}):d;return $CLJS.n(a)?
$CLJS.EX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[JX,tla],null),function(f){return nX.g?nX.g(f,oX):nX.call(null,f,oX)}):d}],null),$CLJS.zN,function(a){return null==a?null:dX(a)},$CLJS.aP,new $CLJS.h(null,1,[GX,qX],null),NX,dX],null),nX=function nX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return nX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
nX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.IA(Fla,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=dX(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,nX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=dX(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,nX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(lX(a))?OX.h(a):$CLJS.wd(a)?$CLJS.Tk.g(function(e){return nX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),GX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.gi($CLJS.rE("Error normalizing form: {0}",$CLJS.H([$CLJS.CV(d)])),new $CLJS.h(null,3,[$CLJS.fA,a,$CLJS.rl,c,wla,b],null),d);}throw e;}};nX.A=1;nX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var sX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(lX(f))?$CLJS.A(f):null},e,a,b,c,d)}();sX.m(null,$CLJS.ci,function(a){return a});
sX.m(null,$CLJS.rG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(mX($CLJS.rG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return sX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,c,$CLJS.Fe($CLJS.il.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,b,$CLJS.Fe(a)],null)});
sX.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,b,a],null)});sX.m(null,$CLJS.dL,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(lX(a))?sX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,a,null],null)});
sX.m(null,MX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,b,new $CLJS.h(null,1,[$CLJS.zB,a],null)],null)});sX.m(null,yla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=tX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=tX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,a,$CLJS.R.j(c,$CLJS.ON,b)],null)});
sX.m(null,zla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=tX(a);return $CLJS.Se.v($CLJS.QV,a,$CLJS.R,$CLJS.H([$CLJS.DP,b]))});
sX.m(null,LX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=tX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.zB);$CLJS.Va(d)||$CLJS.VS.g(d,b)?a=$CLJS.Se.v($CLJS.QV,a,$CLJS.R,$CLJS.H([$CLJS.xG,b])):(c=$CLJS.RA($CLJS.My),$CLJS.n($CLJS.QA("metabase.mbql.util",c))&&(b=$CLJS.rE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.PA("metabase.mbql.util",c,$CLJS.Iw(),b):$CLJS.PA("metabase.mbql.util",c,$CLJS.Iw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),sX.h(new $CLJS.P(null,3,5,$CLJS.Q,[LX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
sX.m(null,$CLJS.LV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=tX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,b,$CLJS.R.j(e,$CLJS.ER,$CLJS.il.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.ME,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var RX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.As,$CLJS.Cs],null)),SX=null,TX=0,UX=0;;)if(UX<TX){var VX=SX.X(null,UX);sX.m(null,VX,function(){return function(a){return uX(a)}}(RX,SX,TX,UX,VX));UX+=1}else{var WX=$CLJS.y(RX);if(WX){var XX=WX;if($CLJS.Ad(XX)){var YX=$CLJS.lc(XX),Gla=$CLJS.mc(XX),Hla=YX,Ila=$CLJS.D(YX);RX=Gla;SX=Hla;TX=Ila}else{var ZX=$CLJS.A(XX);sX.m(null,ZX,function(){return function(a){return uX(a)}}(RX,SX,TX,UX,ZX,XX,WX));RX=$CLJS.B(XX);SX=null;TX=0}UX=0}else break}
sX.m(null,$CLJS.$F,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$F,tX(a),tX(b)],null),c)});sX.m(null,$CLJS.DG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=tX(a);a=$CLJS.n(iX($CLJS.rG,a))?$CLJS.QV.l(c,$CLJS.Jk,$CLJS.H([$CLJS.xG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DG,a],null),b)});
for(var $X=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.iG,$CLJS.CG,$CLJS.qG,$CLJS.jG,$CLJS.es,$CLJS.IG,$CLJS.bs,$CLJS.ds,$CLJS.Yr,$CLJS.$r,$CLJS.VF,$CLJS.bG,$CLJS.TF,$CLJS.dG,$CLJS.UF],null)),aY=null,bY=0,cY=0;;)if(cY<bY){var dY=aY.X(null,cY);sX.m(null,dY,function(){return function(a){return vX(a)}}($X,aY,bY,cY,dY));cY+=1}else{var eY=$CLJS.y($X);if(eY){var fY=eY;if($CLJS.Ad(fY)){var gY=$CLJS.lc(fY),Jla=$CLJS.mc(fY),Kla=gY,Lla=$CLJS.D(gY);$X=Jla;aY=Kla;bY=Lla}else{var hY=$CLJS.A(fY);sX.m(null,hY,
function(){return function(a){return vX(a)}}($X,aY,bY,cY,hY,fY,eY));$X=$CLJS.B(fY);aY=null;bY=0}cY=0}else break}sX.m(null,vla,function(){return null});sX.m(null,$CLJS.EM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EM,sX.h(b),a],null)});
sX.m(null,IX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=sX.h;var e=$CLJS.Q;b=sX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,ula)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.wE,a],null);return c.call(sX,new $CLJS.P(null,3,5,e,[$CLJS.EM,b,a],null))});
for(var iY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zx,$CLJS.sG],null)),jY=null,kY=0,lY=0;;)if(lY<kY){var mY=jY.X(null,lY);sX.m(null,mY,function(){return function(a){return wX(a)}}(iY,jY,kY,lY,mY));lY+=1}else{var nY=$CLJS.y(iY);if(nY){var oY=nY;if($CLJS.Ad(oY)){var pY=$CLJS.lc(oY),Mla=$CLJS.mc(oY),Nla=pY,Ola=$CLJS.D(pY);iY=Mla;jY=Nla;kY=Ola}else{var qY=$CLJS.A(oY);sX.m(null,qY,function(){return function(a){return wX(a)}}(iY,jY,kY,lY,qY,oY,nY));iY=$CLJS.B(oY);jY=null;kY=0}lY=0}else break}
for(var rY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.lG,$CLJS.NG,$CLJS.XF,$CLJS.GG,$CLJS.EG,$CLJS.ao,$CLJS.lk,$CLJS.FG,$CLJS.YF],null)),sY=null,tY=0,uY=0;;)if(uY<tY){var vY=sY.X(null,uY);sX.m(null,vY,function(){return function(a){return xX(a)}}(rY,sY,tY,uY,vY));uY+=1}else{var wY=$CLJS.y(rY);if(wY){var xY=wY;if($CLJS.Ad(xY)){var yY=$CLJS.lc(xY),Pla=$CLJS.mc(xY),Qla=yY,Rla=$CLJS.D(yY);rY=Pla;sY=Qla;tY=Rla}else{var zY=$CLJS.A(xY);sX.m(null,zY,function(){return function(a){return xX(a)}}(rY,sY,tY,uY,
zY,xY,wY));rY=$CLJS.B(xY);sY=null;tY=0}uY=0}else break}sX.m(null,$CLJS.cG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,tX(b),a],null)});
for(var AY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yG,$CLJS.AG],null)),BY=null,CY=0,DY=0;;)if(DY<CY){var EY=BY.X(null,DY);sX.m(null,EY,function(){return function(a){return yX(a)}}(AY,BY,CY,DY,EY));DY+=1}else{var FY=$CLJS.y(AY);if(FY){var GY=FY;if($CLJS.Ad(GY)){var HY=$CLJS.lc(GY),Sla=$CLJS.mc(GY),Tla=HY,Ula=$CLJS.D(HY);AY=Sla;BY=Tla;CY=Ula}else{var IY=$CLJS.A(GY);sX.m(null,IY,function(){return function(a){return yX(a)}}(AY,BY,CY,DY,IY,GY,FY));AY=$CLJS.B(GY);BY=null;CY=0}DY=0}else break}
sX.m(null,$CLJS.LG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LG,sX.h(b),sX.h(a)],null)});
sX.m(null,$CLJS.aG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(sX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aG,b],null)),nX.l(a,$CLJS.H([oX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aG,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[sX.h(v),sX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[sX.h(m),sX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
sX.m(null,$CLJS.KF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KF,sX.h(a),$CLJS.E.g(0,b)?1:sX.h(b)],null),$CLJS.hf.g(sX,c))});
var pX=function pX(a){if($CLJS.yd(a))return $CLJS.Sd(function(d,e,f){return $CLJS.R.j(d,e,pX.h?pX.h(f):pX.call(null,f))},a,a);if($CLJS.xd(a))return $CLJS.Jt(a,pX);if($CLJS.n(lX(a))){var c=function(){try{return sX.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.RA($CLJS.Ky);if($CLJS.n($CLJS.QA("metabase.mbql.normalize",e))){var f=$CLJS.TE("Invalid clause:");f instanceof Error?$CLJS.PA("metabase.mbql.normalize",e,$CLJS.Iw.l($CLJS.H([a])),f):$CLJS.PA("metabase.mbql.normalize",e,$CLJS.Iw.l($CLJS.H([f,
a])),null)}throw $CLJS.gi($CLJS.rE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.CV(d)])),new $CLJS.h(null,1,[$CLJS.jS,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.eg.j($CLJS.be.g($CLJS.jd(c),$CLJS.A(c)),$CLJS.hf.h(pX),$CLJS.Lc(c)):c}return $CLJS.Dd(a)?$CLJS.Tk.g(pX,a):$CLJS.ud(a)?$CLJS.eg.j($CLJS.jd(a),$CLJS.hf.h(pX),a):a},zX=function zX(a){return function f(d,e){try{if($CLJS.n(function(){var x=fX($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=fX($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.Yk.j(e,0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.pE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.hs,null,$CLJS.st,null,$CLJS.MG,null,$CLJS.gs,null,
IX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(PX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.qE(zX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.Yk.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;
}else throw d;else throw x;}}($CLJS.Cf,a)},JY=function JY(a){return function f(d,e){try{var k=fX($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.oG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.hG))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hG,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,HX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,KX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hG,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===
$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,HX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,KX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hG,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.oG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,tX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof
Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.hG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hG,tX(t)],null);throw $CLJS.Y;}catch(Na){if(Na instanceof Error){t=Na;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Na;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.Yk.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.Yk.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.kl.h($CLJS.hf.g(JY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.gX(f,d,e);throw u;}throw K;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.Cf,a)},CX=$CLJS.Rk.g(pX,function(a){var b=DX($CLJS.gG.h(a))?$CLJS.Uk.j(a,$CLJS.gG,kla):a;b=DX($CLJS.fG.h(a))?$CLJS.Uk.j(b,$CLJS.fG,lla):b;b=DX($CLJS.aF.h(a))?$CLJS.Uk.j(b,$CLJS.aF,$CLJS.Ve($CLJS.Tk,rX)):b;b=DX($CLJS.ZR.h(a))?$CLJS.Uk.j(b,$CLJS.ZR,JY):b;return DX($CLJS.SQ.h(a))?$CLJS.Uk.j(b,$CLJS.SQ,nla):b}),Vla=new $CLJS.h(null,3,[$CLJS.tQ,$CLJS.Td,$CLJS.OF,new $CLJS.h(null,2,[$CLJS.SQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.tQ);if($CLJS.n(b))return a=
$CLJS.IV(a,new $CLJS.h(null,1,[$CLJS.tQ,$CLJS.OF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.tQ],null),a=FX.g?FX.g(a,b):FX.call(null,a,b),$CLJS.IV(a,new $CLJS.h(null,1,[$CLJS.OF,$CLJS.tQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.OF],null);return FX.g?FX.g(a,b):FX.call(null,a,b)},$CLJS.NN,new $CLJS.h(null,1,[GX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.OF],null);return FX.g?FX.g(a,b):FX.call(null,a,b)}],null)],null),NX,$CLJS.Td],null),FX=function FX(a){switch(arguments.length){case 1:return FX.h(arguments[0]);
case 2:return FX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};FX.h=function(a){return FX.g(a,$CLJS.Cf)};FX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.IA(Vla,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?rla(a,b):$CLJS.wd(a)?sla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.gi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.fA,a,$CLJS.rl,b],null),d);throw d;}};FX.A=2;
$CLJS.KY=function(){var a=$CLJS.Rk.l(FX,qla,ola,$CLJS.H([nX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.gi($CLJS.rE("Error normalizing query: {0}",$CLJS.H([$CLJS.CV(c)])),new $CLJS.h(null,1,[$CLJS.OF,b],null),c);}throw d;}}}();$CLJS.LY=function LY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.KY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Ht(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=LY.g?LY.g(f,b):LY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};